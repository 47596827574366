<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Transfers" subtitle="View and initiate badge transfers between attendees." :readonly="$authorised('con/badges/access', 'read')">

		<app-content-head-actions-item text="Transfer" icon="transfer" v-on:click="onCreateClick" />

	</app-content-head>

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="Sorry, no transfers found.">

		<app-table>

			<app-table-head :layout="layout">

				<app-table-column text="Date" />
				<app-table-column text="From" />
				<app-table-column text="To" />

			</app-table-head>

			<app-table-body>

				<app-table-row :noClick="true" :layout="layout" v-for="item in collection" :key="item.id">

					<app-table-cell-text :text="item.date | localDate('DD MMMM YYYY')" />
					<app-table-cell-text :text="item.from.user.name" :subtext="item.from.badge.reference" />
					<app-table-cell-text :text="item.to.user.name" :subtext="item.to.badge.reference" />

				</app-table-row>

			</app-table-body>

			<app-table-foot :pagination="pagination" :total="total" />

		</app-table>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			layout: '150px 300px auto',
			endpoint: 'convention/badges/transfers',
			live: 'convention/badges/transfers',
			edit: 'Convention.Badges.Transfers.Edit'
		}

	}

}

</script>

<style scoped>

</style>